import logoLink from '../resources/medi24.svg';

export const cssVariables = {
    primary_light: '#F7C1CC',
    primary: '#4C9B8A',
    primary_dark: '#468f7f',
    text_on_primary: 'white',
    primary_transparent: '#eeeeee',

    secondary: '#E53357',
    secondary_light: '#EBEBEB',
    text_on_secondary: 'white',
    secondary_transparent: '#dedede',

    font: '"Allianz Neo", Helvetica, Arial, sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#4B4B4D',

    border_width: '1px',
    border_style: 'solid',

    active: '#4C9B8A',
    inactive: '#CCC',

    edit_color: '#4C9B8A',
    activate_color: '#A6A6A6',
    deactivate_color: '#A6A6A6',
    delete_color: '#E53357',

    dropdown_background: '#F7C1CC',
    toggle_switch_line: 'white',
};

export const logo = logoLink;
